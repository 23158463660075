import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SSRProvider from 'react-bootstrap/SSRProvider'

import DefaultHeader from '../components/default-header'
import Footer from '../components/footer'
import GdprPanel from '../components/gdpr'
import Navigation from '../components/navigation'
import Seo from '../components/seo'
import SpeakWithAdmissions from '../components/speakWithAdmissions'

import '../styles/content-library.scss'
import NewsletterSignup from '../components/newsletterSignUp'

export default function ContentLibraryContentPage({ data }) {

    const post = data.mdx
    const shortcodes = {
        
      }

    return (      
        <> 
            <SSRProvider>
                <div itemScope itemType="https://schema.org/NewsArticle">
                    <Navigation></Navigation>
                    <DefaultHeader type="contentLibrary" category={post.frontmatter.category} headline={post.frontmatter.headline} heroBackground={post.frontmatter.heroBackground} heroMobileBackground={post.frontmatter.heroMobileBackground} subHeadline={post.frontmatter.subheadline} dateCreated={post.frontmatter.dateCreated } datePublished={post.frontmatter.datePublished} card={`https://web-cdn.meridianuniversity.edu/${post.frontmatter.metadata.ogImage}`}></DefaultHeader>
                    <div style={{display: "none"}}>
                        <span itemProp="author" itemScope itemType="https://schema.org/Person">
                            <span itemProp="name">Meridian Team</span>
                        </span>
                    </div>
                    <div id="body" className="content-library-content-page">
                        <Container fluid="lg">
                            <Row className="justify-content-center py-4 my-4">
                                <Col xl={8} lg={9} md={10} xs={12}>
                                    <MDXProvider components={shortcodes}>
                                        <MDXRenderer>{post.body}</MDXRenderer>
                                    </MDXProvider>      
                                </Col>
                            </Row>
                            <div id="bottom-contact">
                                <Row className="py-4 py-xl-5 justify-content-center">
                                    <Col md={6} className="mb-0 mb-md-4 mb-xl-0">
                                        <SpeakWithAdmissions menuType="bottom-blue"  />
                                    </Col> 
                                    <Col md={6}>
                                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image.webp" alt="learn more about meridian xl" className="img-fluid d-none d-xl-block" />
                                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image-large.webp" alt="learn more about meridian lg" className="img-fluid d-none d-lg-block d-xl-none" />
                                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image-medium.webp" alt="learn more about meridian md" className="img-fluid d-none d-md-block d-lg-none" />
                                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image-small.webp" alt="learn more about meridian sm" className="img-fluid d-block d-md-none" />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/*<div id="suggestedReading">
                            <Container fluid="lg" className="py-lg-5 py-4">
                                <Row className="py-lg-5 py-4">
                                    <Col>
                                        <h2>Suggested Reading</h2>
                                    </Col>
                                </Row>
                            </Container>
                        </div>*/}
                    </div>
                    <Footer />
                    <GdprPanel />
                    <NewsletterSignup />
                </div>
            </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage} 
    />
)

export const query = graphql`
    query($slug: String!) {
        mdx(slug: { eq: $slug }) {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                category
                dateCreated
                datePublished
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
            }
            body
        }
    }   
`
